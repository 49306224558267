<template>
  <div>
    <div v-for="row in hashes" v-bind:key="row" class="card text-left card-file shadow rounded-0 mb-4 border-primary">
      <div class="card-body">
        <div class="d-flex flex-row-reverse">
          <a class="btn btn-link text-danger"  v-if="removable" @click="removeFile(row.documentHash)" style="font-size: 0.9em;"><span v-text="$ml.get('remove')"></span> <font-awesome-icon icon="times-circle"></font-awesome-icon></a>
        </div>
        <div class="d-flex">
          <div class="flex-fill mr-4">
            <div class="font-weight-bold pb-2" v-text="row.fileName"></div>
            <!--
            <div v-if="g_last_query_hashes.includes(row.documentHash)" v-text="row.documentHash" class="bg-success p-1 rounded hash"></div>
            <div v-else v-text="row.documentHash" class="bg-semidark p-1 rounded hash"></div>
            -->
            <div v-if="!compareHashes.length" v-text="row.documentHash" class="bg-semidark p-1 rounded hash"></div>
            <div v-else-if="compareHashes.length && compareHashes.includes(row.documentHash)" v-text="row.documentHash" class="bg-success p-1 rounded hash"></div>
            <div v-else-if="compareHashes.length && !compareHashes.includes(row.documentHash)" v-text="row.documentHash" class="bg-danger p-1 rounded hash"></div>
          </div>
          <div>

            <b-form-group v-if="url_editable"
              id="fieldset-1"
              :label="$ml.get('create_select_download_url')"
              :label-for="'download-'+row.documentHash"
            >
              <b-form-input :id="'download-'+row.documentHash" v-model="row.documentUrl" :placeholder="'https://'" trim></b-form-input>
            </b-form-group>
          </div>
        </div>
        <div class="d-flex" v-if="!url_editable && row.documentUrl!=null">
          <div class="flex-fill">
            <a :href="row.documentUrl">{{row.documentUrl}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {

  computed: mapGetters(['g_last_query_hashes']),
  props: {
    hashes: {
      type: Array,
      default: function () { return [] },
    },
    compareHashes: {
      type: Array,
      default: function () { return [] },
    },
    removable: {
      type: Boolean,
      default: true,
    },
    url_editable: {
      type: Boolean,
      default: true,
    },

  },
  methods: {
    removeFile (id) {
      this.$emit('remove_file', id)
    },
  },
}
</script>
