<template>
  <div id="app" v-bind:class="{'isLogged': isLogged}">
    <div v-if="!isLogged" class="container text-large">
      <div class="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3 pt-3">

        <div v-if="logoAvailable">
          <h5 class="my-0 mr-md-4 font-weight-normal"><a href="#/"><img src="/static/img/logo/50_250.png" width="125px"/></a></h5>
        </div>
        <h5 class="my-0 mr-md-auto "><a href="#/" class="logo text-large text-dark">BlockChain<span>Notarius</span></a></h5>
        <nav class="my-2 my-md-0 mr-md-3">
          <select  class="custom-select special-select" @change="$ml.change($event.target.value)">
            <option
              v-for="lang in $ml.list"
              :key="lang"
              :selected="$ml.current==lang?'selected':''"
              :value="lang"
              v-text="lang"
            />
          </select>
        </nav>
        <b-button v-b-modal.modal-1 class="px-5 py-2"  variant="outline-primary" v-text="$ml.get('login')"></b-button>

        <b-modal v-model="showAdvantechLoginProgress" hide-header-close no-close-on-esc no-close-on-backdrop header-bg-variant="primary" :size="showLicenseChoice? 'xl' :'lg'" centered scrollable>
          <template slot="modal-title">
            <div class="px-3 py-2">
              <h3 v-if="!advantechError && !showLicenseChoice" class="text-white" v-text="$ml.get('login_advantech') + ' ' + $ml.get('is_in_progress') + ' ' + $ml.get('wait_please')"></h3>
              <h3 v-if="!advantechError && showLicenseChoice" class="text-white" v-text="$ml.get('login_advantech_license_choose')"></h3>
              <h3 v-if="advantechError" class="text-white" v-text="$ml.get(advantechError)"></h3>
            </div>
          </template>
          <div v-if="!advantechError && !showLicenseChoice" class="py-4 font-semibold d-flex text-center flex-row justify-content-around ">
            <h3 class="text-centered" v-text="$ml.get(advantechLoginProgress)"></h3>
            <b-spinner variant="primary" label="Spinning"></b-spinner>
          </div>
          <div v-if="!advantechError && showLicenseChoice" class="d-block m-3 ">
            <b-table striped hover small :items="limits" :fields="fields()" class="license-table">
              <template slot="select" slot-scope="data">
                <b-button variant="primary" size="sm" v-on:click="finishLogin(data.item)" v-text="$ml.get('license_use')"></b-button>
              </template>
              <span slot="validTo" slot-scope="data" v-html="(new Date(data.value)).toLocaleDateString('cs')"></span>
              <template slot="usage" slot-scope="data">
                <div>
                  <span v-html="data.item.used + '/' + data.item.usageLimit" class="text-center d-flex justify-content-center"></span>
                  <b-progress :value="data.item.used" :max="data.item.usageLimit"></b-progress>
                </div>
              </template>
            </b-table>
          </div>
          <template slot="modal-footer">
            <b-button class="float-right px-3 py-2" variant="danger" @click="closeAdvantechError" v-text="$ml.get('close')"></b-button>
          </template>
        </b-modal>

        <!-- Modal Component -->
        <b-modal hide-footer hide-header size="lg" centered id="modal-1" @hidden="hfcLogin=false; hfcLoginError=false; login2FAError=false">
          <div class="py-4 font-semibold d-flex text-center flex-row justify-content-around ">
            <b-container>
              <b-row>
                <b-col cols="5"><a class="search" v-bind:class="{'active':tab}" @click="tab=true" href="javascript:void(0)" v-text="$ml.get('login_advantech')"></a></b-col>
                <b-col cols="2"><font-awesome-icon class="text-primary" icon="exchange-alt"></font-awesome-icon></b-col>
                <b-col cols="5"><a class="search" v-bind:class="{'active':!tab}" @click="tab=false" href="javascript:void(0)" v-text="$ml.get('login_elachain')"></a></b-col>
              </b-row>
            </b-container>
          </div>

          <div v-if="tab" class="d-block m-3">
            <div class=" text-center mt-4 mb-4">
              <a class="btn btn-primary py-3 px-5 text-white" @click="redirectAdvantech"><font-awesome-icon icon="sign-in-alt" class="mr-2" /><span>{{$ml.get('login_redirect')}}</span></a>
            </div>
          </div>

          <div v-if="!tab" class="d-block m-3">
            <div class="d-block m-3" v-if="!hfcLogin && !login2FA">
              <h3 class="text-muted" v-text="$ml.get('login_login')"></h3>
              <div class="m-3">
                <p class="text-danger" v-text="$ml.get('login_bad')" v-if="login_error"></p>
                <b-form-group
                  id="fieldset-email"
                  v-bind:class="{'text-danger':login_error}"
                  :label="$ml.get('login_email')"
                  label-for="email"
                >
                  <b-form-input v-bind:class="{'border-danger':login_error}"  id="email" v-model="email" :text="'s'" placeholder="jan.novak@web.cz"></b-form-input>
                </b-form-group>
                <b-form-group
                  id="fieldset-email"
                  :label="$ml.get('login_password')"
                  label-for="password"
                  v-bind:class="{'text-danger':login_error}"
                >
                  <b-form-input v-bind:class="{'border-danger':login_error}" id="password" class="mt-2" v-model="password" type="password"></b-form-input>
                </b-form-group>
                <div class="mt-5">
                  <a :href="'admin/#/auth/resetPassword?redirect=' + baseUrl" class="btn btn-link float-left mt-2" v-text="$ml.get('login_forgotten_password')"></a>
                  <b-button type="submit" class="float-right px-5 py-3" variant="primary" @click="submitLogin" v-text="$ml.get('login_login')"></b-button>
                </div>
              </div>
            </div>
            <div class="d-block m-3" v-if="!hfcLogin && login2FA">
              <h3 class="text-muted" v-text="$ml.get('login_login')"></h3>
              <div class="m-3">
                <p class="text-danger" v-text="$ml.get('authenticator_code_bad')" v-if="login2FAError"></p>
                <b-form-group
                  id="fieldset-email"
                  :label="$ml.get('authenticator_code')"
                  label-for="code2FA"
                  v-bind:class="{'text-danger':login2FAError}"
                >
                  <b-form-input v-bind:class="{'border-danger':login2FAError}" id="code2FA" class="mt-2" v-model="code2FA" type="text"></b-form-input>
                </b-form-group>
                <div class="mt-5">
                  <b-progress :max="timeToPIN" class="w-50 float-left" height="3.6rem" show-progress animated>
                    <b-progress-bar :value="timeLeft" :label="`${timeLeft}s`"></b-progress-bar>
                  </b-progress>
                  <b-button type="submit" class="float-right px-5 py-3" variant="primary" @click="submitLogin2FA" v-text="$ml.get('login_login')"></b-button>
                </div>
              </div>
            </div>
            <div class="d-block m-3" v-if="hfcLogin">
              <div v-if=!hfcLoginError>
                <h3 class="text-center" v-text="$ml.get('login_hfc')"></h3>
                <div class="d-flex text-center flex-row justify-content-around pt-2 pb-2">
                  <b-spinner variant="primary" label="Spinning"></b-spinner>
                </div>
              </div>
              <div v-else>
                <h3 class="text-center text-danger" v-text="$ml.get('login_hfc_error')"></h3>
              </div>
            </div>
          </div>
        </b-modal>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <nav class="col-md-2 d-none d-md-block bg-light sidebar" v-if="isLogged">
          <div class="sidebar-sticky px-2">
            <div class="clearfix mb-5">

              <div v-if="logoAvailable">
                <div class="float-left mr-3">
                  <div class="border rounded-circle" style="width: 50px; height: 50px; background-image: url(/static/img/logo/200_200.png); background-size: contain; background-repeat: no-repeat; background-position: center;">
                  </div>
                </div>
              </div>
              <div class="float-left mt-1">
                <span class="logo">BlockChain<span>Notarius</span></span><br/>
                <small><span class="text-lowercase" v-text="$ml.get('node')"></span> <span v-text="node_id"></span></small>
              </div>
            </div>
            <div v-if="g_limit" class="mb-5">
              <span class="text-center d-flex justify-content-center"><b v-text="g_limit.used + '/' + g_limit.usageLimit"></b></span>
              <b-progress :value="g_limit.used" :max="g_limit.usageLimit" height="2rem"></b-progress>
            </div>
            <table>
              <tr>
                <td style="width: 2em;" class="pb-2 text-right"><font-awesome-icon class="f-18 text-muted" icon="home" style="width: 2rem;"/></td>
                <td class="pb-2"><a href="#/">
                  <span class="mr-auto" v-text="$ml.get('sidebar_dashboard')" @click="refreshDashboard"></span>
                </a>
                </td>
              </tr>
              <tr>
                <td style="width: 2em;" class="pb-2 text-right pr-2 text-muted"><span style="width: 2rem;" v-text="g_entries_my.length"></span></td>
                <td class="pb-2"><a href="#/my/entries">
                  <span class="mr-auto" v-text="$ml.get('sidebar_my_entries')" @click="refreshMyEntries"></span>
                </a>
                </td>
              </tr>
              <tr>
                <td style="width: 2em;" class="pb-2 text-right pr-2 text-muted"><span style="width: 2rem;" v-text="g_entries_my_approved.length"></span></td>
                <td class="pb-2">
                  <a href="#/my/commentedentries">
                    <span class="mr-auto" v-text="$ml.get('sidebar_my_commented_entries')" @click="refreshMyCommentedEntries"></span>
                  </a>
                </td>
              </tr>
            </table>
            <hr/>
            <small class="mt-4 mb-1 d-block text-uppercase text-dark font-weight-bold  mb-3" >Akce</small>

            <table>
              <tr>
                <td style="width: 2em;" class="text-right pb-2">
                  <font-awesome-icon class="f-18 text-muted mr-2" icon="search" style="width: 2rem;"/>
                </td>
                <td class="pb-2">
                  <a href="#/search">
                    <span v-text="$ml.get('sidebar_search')"></span>
                  </a>
                </td>
              </tr>
              <tr>
                <td style="width: 2em;" class="text-right pb-2">
                  <font-awesome-icon class="f-18 text-muted mr-2" icon="plus" style="width: 2rem;"/>
                </td>
                <td class="pb-2"><a href="#/create/entry">
                  <span v-text="$ml.get('sidebar_create')"></span>
                </a>
                </td>
              </tr>
              <tr>
                <td style="width: 2em;" class="text-right pb-2">
                  <font-awesome-icon class="f-18 text-muted mr-2" icon="gavel" style="width: 2rem;"/>
                </td>
                <td class="pb-2">
                  <a href="javascript:void(0)" class="d-flex" v-b-modal.modal-comment>
                    <span class="mr-auto" v-text="$ml.get('sidebar_comment')"></span>
                  </a>

                  <b-modal id="modal-comment" ref="modal-comment" :title="$ml.get('comment_title')" hide-footer>
                    <div class="row">
                      <div class="col-8">

                        <b-form-group
                          id="fieldset-1"
                          v-bind:class="{'text-danger':comment_error}"
                          :label="$ml.get('comment_id')"
                          label-for="input-1"
                        >
                          <b-form-input id="input-1" v-model="comment_id" trim></b-form-input>
                        </b-form-group>
                      </div>
                      <div class="col-4">
                        <b-form-group
                          id="fieldset-1"
                          v-bind:class="{'text-danger':comment_error}"
                          :label="$ml.get('comment_pin')"
                          label-for="input-1"
                        >
                          <b-form-input id="input-1" type="number" v-model="comment_pin" trim></b-form-input>
                        </b-form-group>
                      </div>
                    </div>
                    <div class="text-right">
                      <a class="btn btn-outline-primary" @click="viewToApprove" v-text="$ml.get('comment_ok')"></a>
                    </div>
                  </b-modal>
                </td>
              </tr>
            </table>
          </div>
        </nav>
        <main  v-bind:class="{'col-md-9 ml-sm-auto col-lg-10 px-0': isLogged,'col-md-12 ml-sm-12 col-lg-12 px-0': !isLogged}">
          <nav v-if="isLogged" class="text-right mt-3">
            <b v-text="fullname"></b> (<small v-text="parentOrganization===organization? organization : parentOrganization + '/' + organization"></small>)
            <select  class="custom-select special-select mx-4" style="width: initial;" @change="$ml.change($event.target.value)">
              <option
                v-for="lang in $ml.list"
                :key="lang"
                :selected="$ml.current==lang?'selected':''"
                :value="lang"
                v-text="lang"
              />
            </select>
            <a class="btn btn-link" href="#" @click="submitLogout" v-text="$ml.get('sidebar_logout')"></a><font-awesome-icon icon="sign-out-alt" class="mr-5"/>
          </nav>
          <router-view></router-view>
          <nav class="px-4 mt-5 mb-5" v-bind:class="{'container': !isLogged}">
            <hr size="10"/>
            <ul class="list-inline">
              <li class="list-inline-item mr-4"><a class="link-underline text-dark font-semibold" href="https://wiki.elachain.cz/doku.php?id=notarius:start" v-text="$ml.get('about')"></a></li>
              <li class="list-inline-item"><a class="link-underline text-dark font-semibold" href="mailto:support@elachain.cz" v-text="$ml.get('support')"></a></li>
            </ul>
          </nav>
        </main>
      </div>
    </div>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'app',
  methods: {
    ...mapActions(['login', 'set_approved_newest', 'set_nodes', 'set_newest', 'set_entries_my', 'set_entries_my_approved', 'logout', 'set_limit']),
    viewToApprove () {
      this.backend.viewToApprove(this.comment_id, this.comment_pin).then(e => {
        if (e === false) {
          this.comment_error = true
        } else {
          this.comment_error = false
          this.$router.push({ path: '/entry/' + this.comment_id + '/' + this.comment_pin })
          this.$refs['modal-comment'].hide()
        }
      }).catch(e => {
        this.comment_error = true
      })
    },
    redirectAdvantech () {
      this.backend.getAdvantechID().then(r => {
        if (r !== false) {
          var clientID = r
          var scope = 'https%3A%2F%2Fmember.advantech.com%2Fuser%2Fprofile.read%20https%3A%2F%2Fmember.advantech.com%2Fuser%2Femail.read'
          var lang = 'en-US'
          switch (this.$ml.current) {
            case 'zh-tw':
              lang = 'zh-TW'
              break
            case 'zh-cn':
              lang = 'zh-CN'
              break
          }
          var redirectUrl = this.baseUrl
          window.location.href = 'https://member.advantech.com/oauth2/authorize.ashx?response_type=code&client_id=' + clientID + '&scope=' + scope + '&redirect_uri=' + encodeURIComponent(redirectUrl) + '&lang=' + lang
        }
      })
    },
    countDownTimer () {
      if (this.timeLeft > 0) {
        setTimeout(() => {
          this.timeLeft -= 1
          this.countDownTimer()
        }, 1000)
      } else {
        this.login2FA = false
      }
    },
    submitLogin () {
      this.backend.login2FAstep1(this.email, this.password).then(r => {
        if (r !== false) {
          if (r.email) {
            this.login_error = false
            this.hfcLogin = true
            this.backend.hfcLogin().then(re => {
              if (re !== false) {
                this.hfcLogin = false
                this.hfcLoginError = false
                this.email = ''
                this.password = ''
                this.login(r)
                this.backend.myNewestEntries().then(e => {
                  if (e !== false) { this.set_newest(e) }
                })
                this.backend.myNewestApproved().then(e => {
                  if (e !== false) { this.set_approved_newest(e) }
                })
                this.backend.getMyEntries().then(e => {
                  if (e !== false) { this.set_entries_my(e) }
                })
                this.backend.getMyApprovedEntries().then(e => {
                  if (e !== false) { this.set_entries_my_approved(e) }
                })
                if (this.$router.currentRoute.path === '/result') {
                  this.$router.push({ path: '/search/result' })
                }
              } else {
                this.hfcLoginError = true
                this.submitLogout()
              }
            },
            )
          } else {
            this.login2FA = true
            this.timeToPIN = 60
            this.timeLeft = 60
            this.countDownTimer()
          }
        } else {
          this.login_error = true
        }
      },
      )
    },
    submitLogin2FA () {
      this.backend.login2FAstep2(this.code2FA).then(r => {
        if (r !== false) {
          this.login2FAError = false
          this.hfcLogin = true
          this.login2FA = false
          this.timeLeft = 0
          this.backend.hfcLogin().then(re => {
            if (re !== false) {
              this.hfcLogin = false
              this.hfcLoginError = false
              this.email = ''
              this.password = ''
              this.code2FA = ''
              this.login(r)
              this.backend.myNewestEntries().then(e => {
                if (e !== false) { this.set_newest(e) }
              })
              this.backend.myNewestApproved().then(e => {
                if (e !== false) { this.set_approved_newest(e) }
              })
              this.backend.getMyEntries().then(e => {
                if (e !== false) { this.set_entries_my(e) }
              })
              this.backend.getMyApprovedEntries().then(e => {
                if (e !== false) { this.set_entries_my_approved(e) }
              })
              if (this.$router.currentRoute.path === '/result') {
                this.$router.push({ path: '/search/result' })
              }
            } else {
              this.hfcLoginError = true
              this.submitLogout()
            }
          },
          )
        } else {
          this.login2FAError = true
        }
      },
      )
    },
    submitLoginAdvantech (code) {
      this.advantechLoginProgress = 'login_advantech_user'
      this.backend.loginAdvantech(code, this.baseUrl).then(r => {
        if (r !== false) {
          this.toLogin = r
          this.advantechLoginProgress = 'login_advantech_license'
          this.backend.getLimitsAdvantech().then(l => {
            if (l !== false) {
              if (l === null) {
                this.advantechError = 'login_advantech_error_license'
                return
              }
              this.limits = l
              this.showLicenseChoice = true
            } else {
              this.advantechError = 'login_advantech_error'
            }
          })
        } else {
          this.advantechError = 'login_advantech_error'
        }
      },
      )
    },
    finishLogin (limit) {
      this.backend.useLimitAdvantech(limit).then(e => {
        if (e !== false) {
          this.showAdvantechLoginProgress = false
          this.showLicenseChoice = false
          this.advantechError = null
          this.limits = []
          this.login(this.toLogin)
          this.toLogin = null
          this.set_limit(limit)
          this.backend.myNewestEntries().then(e => {
            if (e !== false) { this.set_newest(e) }
          })
          this.backend.organizationNewestEntries().then(e => {
            if (e !== false) { this.set_org_newest(e) }
          })
          this.backend.getMyEntries().then(e => {
            if (e !== false) { this.set_entries_my(e) }
          })
          this.backend.getMyApprovedEntries().then(e => {
            if (e !== false) { this.set_entries_my_approved(e) }
          })
          this.backend.getNodes().then(e => {
            if (e !== false) { this.set_nodes(e) }
          })
          if (this.$router.currentRoute.path === '/result') {
            this.$router.push({ path: '/search/result' })
          }
        } else {
          this.showLicenseChoice = false
          this.advantechError = 'login_advantech_error'
        }
      })
    },
    submitLogout () {
      this.toLogin = null
      this.logout()
      this.backend.logout()
      if (this.$router.currentRoute.path !== '/') {
        this.$router.push({ path: '/' })
      }
    },
    refreshDashboard () {
      this.backend.myNewestEntries().then(e => {
        if (e !== false) { this.set_newest(e) }
      })
      this.backend.myNewestApproved().then(e => {
        if (e !== false) { this.set_approved_newest(e) }
      })
    },
    refreshMyEntries () {
      this.backend.getMyEntries().then(e => {
        if (e !== false) { this.set_entries_my(e) }
      })
    },
    refreshMyCommentedEntries () {
      this.backend.getMyApprovedEntries().then(e => {
        if (e !== false) { this.set_entries_my_approved(e) }
      })
    },
    closeAdvantechError () {
      this.showAdvantechLoginProgress = false
      this.showLicenseChoice = false
      this.advantechError = null
      this.toLogin = null
      this.limits = []
    },
    fields () {
      return [
        {
          key: 'select',
          label: '',
          tdClass: value => { return 'min' },
        },
        {
          key: 'userId',
          label: this.$ml.get('license_id'),
          sortable: true,
          tdClass: value => { return 'min' },
        },
        {
          key: 'name',
          label: this.$ml.get('license_subscription_id'),
          sortable: true,
          tdClass: value => { return 'min' },
        },
        {
          key: 'usage',
          label: this.$ml.get('license_usage'),
          tdClass: value => { return 'min' },
        },
        {
          key: 'validTo',
          label: this.$ml.get('license_expiration'),
          sortable: true,
          tdClass: value => { return 'min' },
        },
      ]
    },
  },
  computed: {
    ...mapGetters(['isLogged', 'fullname', 'organization', 'parentOrganization', 'g_entries_my', 'g_entries_my_approved', 'g_limit']),
    baseUrl: {
      get: function () {
        return window.location.protocol + '//' + window.location.host
      },
    },
  },
  data () {
    return {
      email: '',
      password: '',
      login_error: false,
      comment_error: false,
      comment_id: '',
      comment_pin: '',
      node_id: '',
      logoAvailable: false,
      tab: true,
      showAdvantechLoginProgress: false,
      showLicenseChoice: false,
      advantechLoginProgress: 'login_advantech_user',
      advantechError: null,
      toLogin: null,
      limits: [],
      hfcLogin: false,
      hfcLoginError: false,
      login2FA: false,
      login2FAError: false,
      code2FA: '',
      timeToPIN: 0,
      timeLeft: 0,
    }
  },
  created () {
    this.backend.nodeId().then(e => {
      if (e !== false) {
        this.node_id = e
      }
    })
    this.backend.logosAvailable().then(e => {
      this.logoAvailable = e
    })
    const params = (new URL(document.location)).searchParams
    const code = params.get('code')
    if (code) {
      window.history.replaceState(null, null, window.location.pathname)
      this.showAdvantechLoginProgress = true
      this.submitLoginAdvantech(code)
    }
  },
}
</script>

<style lang="scss">
@import 'assets/custom.scss';
@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap-vue/src/index.scss';

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  min-height: 100%;
}

body,
html {
  margin: 0;
  padding: 0;
}
.f-18 { font-size: 18px; }

.fixed-bottom-m {
  position: fixed !important;
  bottom: 0;
  z-index: 1030;
  width: 100%;
}

.sidebar {
  position: fixed !important;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}

nav.navbar,
.navbar {
  position: fixed !important;
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.sidebar-bottom {
  bottom: 0;
  position: absolute;
  left: 0;
  right: 0;
}

.logo {
  font-family: 'Barlow SemiCondensed', sans-serif;
  user-select: none;
}

.logo span {
  font-weight: bold;
  font-stretch: condensed;
}

a.search.active {
  color: black;
  border-bottom: 2px #2536d4 solid;
}

a.search:hover {
  text-decoration: none;
}

.form-cust {
  width: auto;
}

.license-table tr td.min,
.license-table tr th.min {
  width: 1% !important;
  white-space: nowrap;
  font-size: 14px;
}

.license-table thead {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome and Opera */
  font-size: 16px;
}

</style>
