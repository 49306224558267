<template>
  <div class="container component mt-3 px-4">
    <h1 class="text-xxx-large mb-5" v-text="this.$ml.get(this.g_query_header)"></h1>
    <entry-table :items="this.g_last_query"></entry-table>
  </div>
</template>
<script>
import EntryTable from './TableSearch'
import { mapGetters } from 'vuex'
export default {
  components: { EntryTable },
  computed: mapGetters(['g_last_query', 'g_query_header']),
}
</script>
