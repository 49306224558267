import { render, staticRenderFns } from "./DragDropFileSec.vue?vue&type=template&id=24613ca6&"
import script from "./DragDropFileSec.vue?vue&type=script&lang=js&"
export * from "./DragDropFileSec.vue?vue&type=script&lang=js&"
import style0 from "./DragDropFileSec.vue?vue&type=style&index=0&id=24613ca6&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports