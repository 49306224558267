import Vue from 'vue'
import { MLInstaller, MLCreate, MLanguage } from 'vue-multilanguage'

Vue.use(MLInstaller)

function storageAvailable (type) {
  var storage
  try {
    storage = window[type]
    var x = '__storage_test__'
    storage.setItem(x, x)
    storage.removeItem(x)
    return storage
  } catch (e) {
    return null
  }
}

function getInitLang () {
  var init = 'en-us'
  var available = ['en-us', 'cs', 'zh-tw', 'zh-cn']
  const local = storageAvailable('localStorage')
  if (local) {
    var fromStorage = local.getItem('vueml-lang')
    if (fromStorage) {
      if (available.indexOf(fromStorage) > -1) {
        return fromStorage
      } else {
        local.removeItem('vueml-lang')
      }
    }
  }
  const browserLanguage = (window.navigator.language || window.navigator.browserLanguage).toLowerCase()
  if (available.indexOf(browserLanguage) > -1) {
    init = browserLanguage
  } else {
    const shortLang = browserLanguage.split('-')[0]
    switch (shortLang) {
      case 'en':
        init = 'en-us'
        break
      case 'zh':
        init = 'zh-cn'
        break
      default:
        break
    }
  }
  return init
}

export default new MLCreate({
  initial: getInitLang(),
  save: process.env.NODE_ENV === 'production',
  languages: [
    new MLanguage('en-us').create({
      about: 'About',
      action: 'action',
      approval_allow: 'Document with approval',
      approve_at: 'Approved at',
      approve_by: 'Approver',
      approve_comment: 'Comment',
      approve_node: 'Approved on node',
      approvestatus_approved: 'Approved',
      approvestatus_approvedWithComments: 'Approved with comments',
      approvestatus_notApproved: 'Not approved',
      approvestatus_waitingForApproval: 'Waiting for approval',
      approvestatus_withoutApproval: 'No approval needed',
      authenticator_code: "Authenticator code",
      authenticator_code_bad: "Wrong code",
      change_record: 'Edit entry',
      close: 'Close',
      comment: 'Comment',
      comment_approved: 'Approved',
      comment_approved_with: 'Approved with comments',
      comment_id: 'Entry ID',
      comment_notapproved: 'Not approved',
      comment_ok: 'OK',
      comment_pin: 'PIN',
      comment_t: 'Approve entry',
      comment_title: 'Search entry for approval',
      compare: 'Compare',
      confirm: 'Confirm',
      confirmed_hash: 'Confirmed hash:',
      confirmed_results: 'Verified results',
      create_confirm_subtitle: 'After creation, you wont be able to change this record, only add new record.',
      create_confirm_title: 'Do you really want to create this record?',
      create_create: 'Create new entry',
      create_metadata: '2. Entry metadata',
      create_metadata_anotation_placeholder: 'max. 500 characters',
      create_metadata_anotation_title: 'Annotation',
      create_metadata_approval_check: 'Allow approving document',
      create_metadata_approval_title: 'Approving document',
      create_metadata_entryname_help: '* Required',
      create_metadata_entryname_placeholder: 'example. Contract with company ABC',
      create_metadata_entryname_title: 'Entry name',
      create_metadata_id1: 'Your custom sign (ID1) for entry',
      create_metadata_id2: 'Your custom sign (ID2) for entry',
      create_metadata_visibility: 'Entry visibility',
      create_metadata_visibility_priv: 'Private',
      create_metadata_visibility_pub: 'Public',
      create_select_download_url: 'URL to download file',
      create_select_file: 'File select',
      create_select_file_add: 'Add file',
      create_select_file_select: 'Select entry files',
      create_select_file_text_1: 'Drag&drop/select one or more files (.pdf, .jpg, .png)',
      create_select_file_text_2: 'Files are used to calculate Sha-256 hash locally on your machine. They won´t be uploaded.',
      create_select_files: '1. Files select',
      create_select_hashes: 'Select file(s) hashcode(s)',
      create_select_hashes_add: 'Add this file to entry',
      create_select_hashes_hash_help: 'Entered hashcode should by created by algorithm sha-256 for comparability reasons.',
      create_select_hashes_hash_placeholder: 'example. jidqwuqwhd0f368a8e940524eb9649fac',
      create_select_hashes_hash_title: 'File hashcode',
      create_select_hashes_name_placeholder: 'example. contract.pdf',
      create_select_hashes_name_title: 'Filename',
      create_select_no_file: 'No files selected',
      create_select_remove: 'Remove file',
      create_select_title: 'Files that will be added to this entry',
      create_title: 'Create new entry',
      dashboard_actions: 'Actions',
      dashboard_comment: 'Approve entry',
      dashboard_create: 'Create entry',
      dashboard_search: 'Search entry',
      dashboard_title: 'Dashboard',
      detail_active: 'Active entry',
      detail_anotation: 'Annotation',
      detail_author: 'Author (User/Company)',
      detail_comment_process: 'Approval process',
      detail_disabled: 'Disabled entry',
      detail_eid10: 'Description',
      detail_eid1: 'Optional record identifier (ID1)',
      detail_eid20: 'Description',
      detail_eid2: 'Optional record identifier (ID2)',
      detail_files: 'Entry file(s)',
      detail_node: 'Network node, where record was created',
      detail_timestamp: 'Created',
      entry_id: 'Entry ID',
      entry_private: 'Private entry',
      entry_public: 'Public entry',
      example: 'example:',
      expires: 'expires',
      hash_info: 'Inserted hashcode should be created with SHA-256 algorithm. With different algorithm, there can be problem with record searching with this file.',
      here: 'here',
      is_in_progress: 'is in progress...',
      landingpage_text: 'Service BlockchainNotarius uses blockchain technology for secure validation of electronic documents.',
      landingpage_text_1: 'Presence of entry can be verified on different node. Node list can be found',
      landingpage_title2: 'that you can trust',
      landingpage_title: 'Electronic documents,',
      landingpage_via_files: 'With files',
      landingpage_via_files_text_1: 'Input file hashcode in plaintext',
      landingpage_via_files_text_2: 'Files won´t be uploaded, they only serve for calculating hashcode',
      landingpage_via_hash: 'Record ID',
      landingpage_via_hash_text_1: 'Input six-digit entry ID, which was assigned to the entry after upload, or input file hashcodes from said entry divided by semicolon',
      langingpage_text_2: 'If you want submit a entry or search for entries using metadata,',
      license_use: 'Select',
      license_id: 'ID',
      license_subscription_id: 'Subscription ID',
      license_usage: 'Usage',
      license_expiration: 'Expires',
      login: 'Login',
      login_advantech: 'MyAdvantech login',
      login_advantech_error: 'Something went wrong... Please try again later.',
      login_advantech_error_license: 'Unfortunately there is no valid license associated with Your account.',
      login_advantech_user: 'Getting user information...',
      login_advantech_license: 'Getting active licenses...',
      login_advantech_license_choose: 'Please choose the license You would like to use.',
      login_bad: 'Wrong username or password',
      login_elachain: 'ElAchain login',
      login_email: 'Email',
      login_forgotten_password: 'Forgotten password?',
      login_hfc: 'Connection to ElAchain network...',
      login_hfc_error: 'Error connection to ElAchain network. Try later or conntact node administrator.',
      login_login: 'Login',
      login_password: 'Password',
      login_redirect: 'Redirect to MyAdvantech login',
      mycommentedentries_title: 'My approved entries',
      myentry_title: 'My entries',
      no: 'No',
      no_file_selected: 'No files selected',
      no_search_results: 'No results found.',
      node: 'Node',
      or: 'or',
      query_anotation: 'Annotation',
      query_author: 'Documents author',
      query_author_p: 'e.g. jannovak@elachain.cz',
      query_ex_p: 'e.g. contract number or other identifier chosen by author',
      query_exid1: 'Optional ID1',
      query_exid2: 'Optional ID2',
      query_from: 'Created from',
      query_name: 'Entry name',
      query_name_p: 'e.g. Used template',
      query_on_node: 'Created on node',
      query_on_node_p: 'blockchain.elachain.cz',
      query_org: 'Organization of documents author',
      query_org_p: 'e.g. blockchain.elachain.cz',
      query_state: 'Entry state',
      query_state_a: 'Active',
      query_state_n: 'Inactive',
      query_state_v: 'All',
      query_to: 'Created to',
      query_where: 'Created on node',
      remove: 'Remove',
      search_advanced: 'By metadata',
      search_entry: 'Search for entry',
      search_files: 'By files / ID',
      search_results: 'Search results',
      search_title: 'Entry search',
      sidebar_comment: 'Approve entry',
      sidebar_create: 'Create entry',
      sidebar_dashboard: 'Dashboard',
      sidebar_logout: 'Logout',
      sidebar_my_commented_entries: 'My approved entries',
      sidebar_my_entries: 'My entries',
      sidebar_search: 'Search entry',
      submit_record: 'Yes, create',
      support: 'Support',
      table_approval_state: 'Approval state',
      table_created: 'Created',
      table_entry_files: 'Files in entry',
      table_entry_name: 'Entry name',
      table_id: 'Entry ID',
      table_state: 'State',
      wait_please: 'Please wait',
      yes: 'Yes',
    }),

    new MLanguage('cs').create({
      about: 'O službě',
      action: 'akce',
      approval_allow: 'Schvalování dokumentu',
      approve_at: 'Schváleno',
      approve_by: 'Schvalovatel',
      approve_comment: 'Komentář',
      approve_node: 'Schváleno na nodu',
      approvestatus_approved: 'Schváleno',
      approvestatus_approvedWithComments: 'Schváleno s výhradami',
      approvestatus_notApproved: 'Zamítnuto',
      approvestatus_waitingForApproval: 'Čeká na schválení',
      approvestatus_withoutApproval: 'Bez schvalování',
      authenticator_code: "Kód z autentikátoru",
      authenticator_code_bad: "Špatný kód",
      change_record: 'Upravit záznam',
      close: 'Zavřít',
      comment: 'Komentář',
      comment_approved: 'Schválit',
      comment_approved_with: 'Schválit s výhradami',
      comment_id: 'ID záznamu',
      comment_notapproved: 'Zamítnout',
      comment_ok: 'Potvrdit',
      comment_pin: 'PIN k záznamu',
      comment_t: 'Schválit záznam',
      comment_title: 'Vyhledat záznam pro schválení',
      compare: 'Porovnat',
      confirm: 'Schválit',
      confirmed_hash: 'Ověřené hashe:',
      confirmed_results: 'Ověřené záznamy',
      create_confirm_subtitle: 'Po vložení již nebude možné tento záznam upravit, pouze vložit nový.',
      create_confirm_title: 'Opravdu chcete vytvořit tento záznam?',
      create_create: 'Vytvořit nový záznam',
      create_metadata: '2. Metadata dokumentu',
      create_metadata_anotation_placeholder: 'max. 500 znaků',
      create_metadata_anotation_title: 'Anotace',
      create_metadata_approval_check: 'Povolit schvalování dokumentu',
      create_metadata_approval_title: 'Schvalování dokumentu',
      create_metadata_entryname_help: '* Povinný údaj',
      create_metadata_entryname_placeholder: 'př. Kontrakt s firmou ABC',
      create_metadata_entryname_title: 'Název dokumentu',
      create_metadata_id1: 'Vaše volitelná značka (ID1) záznamu',
      create_metadata_id2: 'Vaše volitelná značka (ID2) záznamu',
      create_metadata_visibility: 'Viditelnost dokumentu',
      create_metadata_visibility_priv: 'Soukromý',
      create_metadata_visibility_pub: 'Veřejný',
      create_select_download_url: 'Ke stažení z URL',
      create_select_file: 'Vybrat soubory',
      create_select_file_add: 'Přidat soubor',
      create_select_file_select: 'Vyberte soubory záznamu',
      create_select_file_text_1: 'Přetáhněte / vyberte jeden nebo více souborů (.pdf, .jpg, .png,)',
      create_select_file_text_2: 'Soubory nebudou nahrány na server, slouží pouze ke spočítání hashcode pomocí algoritmu SHA-256',
      create_select_files: '1. Výběr souborů',
      create_select_hashes: 'Zadat kód(y) soubor(ů)',
      create_select_hashes_add: 'Přidat tento soubor do záznamu',
      create_select_hashes_hash_help: 'Zadaný hashkód by měl být vytvořen algoritmem SHA-256. Pokud použijete k zakódování jiný algoritmus, mohou nastat problémy při zpětném vyhledávání záznamu pomocí souboru.',
      create_select_hashes_hash_placeholder: 'př. jidqwuqwhd0f368a8e940524eb9649fac',
      create_select_hashes_hash_title: 'Hashkód souboru',
      create_select_hashes_name_placeholder: 'př. smlouva.pdf',
      create_select_hashes_name_title: 'Název souboru',
      create_select_no_file: 'Zatím jste nepřidali žádné soubory.',
      create_select_remove: 'Odstranit soubor',
      create_select_title: 'Soubory, které budou vloženy do záznamu',
      create_title: 'Vytvořit nový záznam',
      dashboard_actions: 'Akce',
      dashboard_comment: 'Schválit záznam',
      dashboard_create: 'Vytvořit nový záznam',
      dashboard_search: 'Vyhledat záznam',
      dashboard_title: 'Dashboard',
      detail_active: 'Aktivní záznam',
      detail_anotation: 'Anotace',
      detail_author: 'Autor (Uživatel/Společnost)',
      detail_comment_process: 'Stav schvalování',
      detail_disabled: 'Neaktivní záznam',
      detail_eid10: 'Popis značky',
      detail_eid1: 'Volitelný identifikátor (ID1) záznamu',
      detail_eid20: 'Popis značky',
      detail_eid2: 'Volitelný identifikátor (ID2) záznamu',
      detail_files: 'Soubor(y) v záznamu',
      detail_node: 'Uzel sítě, kde byl záznam vložen',
      detail_timestamp: 'Vytvořeno',
      entry_id: 'ID záznamu',
      entry_private: 'Privátní záznam',
      entry_public: 'Veřejný záznam',
      example: 'např.',
      expires: 'expiruje',
      hash_info: 'Zadaný hashkód by měl být vytvořen algoritmem SHA-256. Pokud použijete k zakódování jiný algoritmus, mohou nastat problémy při zpětném vyhledávání záznamu pomocí souboru.',
      here: 'zde',
      is_in_progress: 'právě probíhá...',
      landingpage_text: 'Služba BlockchainNotarius využívá technologie blockchain k bezpečnému ověřování elektronických dokumentů.',
      landingpage_text_1: 'Přítomnost záznamu můžete ověřit i na jiném z uzlů. Seznam uzlů naleznete',
      landingpage_title2: 'kterým můžete věřit',
      landingpage_title: 'Elektronické dokumenty,',
      landingpage_via_files: 'Přetáhněte / vyberte jeden nebo více souborů',
      landingpage_via_files_text_1: 'Zadat hashkód souboru v textové podobě',
      landingpage_via_files_text_2: 'Soubory nebudou nahrány na server, slouží pouze ke spočítání hashkódů',
      landingpage_via_hash: 'Vložte ID záznamu',
      landingpage_via_hash_text_1: 'Zadejte šestimístné ID záznamu, které bylo přiděleno záznamu po nahrání, anebo hashkódy jednotlivých souborů záznamu oddělené středníkem',
      langingpage_text_2: 'Pokud chcete vložit záznam anebo vyhledávat pomocí metadat záznamů,',
      license_use: 'Vybrat',
      license_id: 'ID',
      license_subscription_id: 'Subscription ID',
      license_usage: 'Vužití',
      license_expiration: 'Platnost do',
      login: 'Přihlásit se',
      login_advantech: 'MyAdvantech přihlášení',
      login_advantech_error: 'Něco se pokazilo... Zkuste to prosím později.',
      login_advantech_error_license: 'Bohužel nebyla nalezena žádná platná license svázaná s Vaším účtem.',
      login_advantech_user: 'Načítání informací o uživateli...',
      login_advantech_license: 'Načítání aktivních licencí...',
      login_advantech_license_choose: 'Vyberte si prosím licensi, kterou chcete používat.',
      login_bad: 'Wrong username or password',
      login_elachain: 'ElAchain přihlášení',
      login_email: 'Email',
      login_forgotten_password: 'Zapomenuté heslo?',
      login_hfc: 'Připojování do sítě ElAchain...',
      login_hfc_error: 'Chyba při připojování do ElAchain sítě. Zkuste to později nebo kontaktujte správce nodu.',
      login_login: 'Přihlásit se',
      login_password: 'Heslo',
      login_redirect: 'Přesměrovat na MyAdvantech přihlášení',
      mycommentedentries_title: 'Mnou schvalované dokumenty',
      myentry_title: 'Moje záznamy',
      no: 'Ne',
      no_file_selected: 'Zatím jste nevybrali žádné soubory',
      no_search_results: 'Nenalezeny žádné výsledky.',
      node: 'Uzel',
      or: 'nebo',
      query_anotation: 'Anotace',
      query_author: 'Autor dokumentu',
      query_author_p: 'např. jannovak@elachain.cz',
      query_ex_p: 'např. číslo smlouvy či jiný autorem zvolený identifikátor',
      query_exid1: 'Volitelné ID1',
      query_exid2: 'Volitelné ID2',
      query_from: 'Vloženo od',
      query_name: 'Název záznamu',
      query_name_p: 'např. Užitný vzor',
      query_on_node: 'Vloženo na uzlu',
      query_on_node_p: 'blockchain.elachain.cz',
      query_org: 'Organizace autora dokumentu',
      query_org_p: 'např. blockchain.elachain.cz',
      query_state: 'Stav záznamu',
      query_state_a: 'Aktivní',
      query_state_n: 'Neaktivní',
      query_state_v: 'Všechny',
      query_to: 'Vloženo do',
      query_where: 'Vloženo na uzlu',
      remove: 'Odebrat',
      search_advanced: 'Pomocí metadat',
      search_entry: 'Vyhledat záznam',
      search_files: 'Pomocí souborů / ID',
      search_results: 'Nalezené záznamy',
      search_title: 'Vyhledat záznam',
      sidebar_comment: 'Schválit záznam',
      sidebar_create: 'Vytvořit nový záznam',
      sidebar_dashboard: 'Dashboard',
      sidebar_logout: 'Odhlásit se',
      sidebar_my_commented_entries: 'Mnou schvalované záznamy',
      sidebar_my_entries: 'Moje záznamy',
      sidebar_search: 'Vyhledat záznam',
      submit_record: 'Ano, vytvořit',
      support: 'Podpora',
      table_approval_state: 'Stav schválení',
      table_created: 'Vloženo',
      table_entry_files: 'Soubory záznamu',
      table_entry_name: 'Název záznamu',
      table_id: 'ID záznamu',
      table_state: 'Stav',
      wait_please: 'Počkejte prosím',
      yes: 'Ano',
    }),

    new MLanguage('zh-tw').create({
      about: '關於',
      action: '行動',
      approval_allow: '批准的文件',
      approve_at: '批准于',
      approve_by: '批准者',
      approve_comment: '評論',
      approve_node: 'Approved on node',
      approvestatus_approved: '已批准',
      approvestatus_approvedWithComments: '已批准並包含評論',
      approvestatus_notApproved: '未批准',
      approvestatus_waitingForApproval: '等待批准',
      approvestatus_withoutApproval: '未经批准',
      authenticator_code: "Authenticator code",
      authenticator_code_bad: "Wrong code",
      change_record: '編輯條目',
      close: '關閉',
      comment: '評論',
      comment_approved: '已批准',
      comment_approved_with: '已批准並包含評論',
      comment_id: '條目ID',
      comment_notapproved: '未批准',
      comment_ok: '好',
      comment_pin: '密碼',
      comment_t: '條目批准',
      comment_title: '搜索條目以供批准',
      compare: '比較',
      confirm: '確認',
      confirmed_hash: '已確認的散列',
      confirmed_results: '已驗證的結果',
      create_confirm_subtitle: '創建後, 您將無法更改此記錄, 只能添加新記錄。',
      create_confirm_title: '是否確實要創建此記錄？',
      create_create: '創建新條目',
      create_metadata: '2. 條目元數據',
      create_metadata_anotation_placeholder: '最多500個字符',
      create_metadata_anotation_title: '註釋',
      create_metadata_approval_check: '允許批准文件',
      create_metadata_approval_title: '文件正被批准',
      create_metadata_entryname_help: '* 要求的',
      create_metadata_entryname_placeholder: '比如, 與ABC公司簽訂合同',
      create_metadata_entryname_title: '條目名稱',
      create_metadata_id1: '您的自定義標誌 (ID1) 以供條目',
      create_metadata_id2: '您的自定義標誌 (ID2) 以供條目',
      create_metadata_visibility: '條目可見性',
      create_metadata_visibility_priv: '私人',
      create_metadata_visibility_pub: '公開',
      create_select_download_url: '下載文件的網址',
      create_select_file: '選擇文件選擇',
      create_select_file_add: '添加文件',
      create_select_file_select: '選擇條目文件',
      create_select_file_text_1: '拖放/選擇一個或多個文件 (.pdf, .jpg, .png)',
      create_select_file_text_2: '文件用於在計算機上本地計算Sha-256散列。文件不會被上載。',
      create_select_files: '1. 文件選擇',
      create_select_hashes: '選擇文件 散列碼',
      create_select_hashes_add: '將此文件添加到條目中',
      create_select_hashes_hash_help: '出於可比性原因, 輸入的散列碼應由sha-256算法創建。',
      create_select_hashes_hash_placeholder: '比如. jidqwuqwhd0f368a8e940524eb9649fac',
      create_select_hashes_hash_title: '文件 散列碼',
      create_select_hashes_name_placeholder: '比如. 合同.pdf',
      create_select_hashes_name_title: '文件名',
      create_select_no_file: '未選擇任何文件',
      create_select_remove: '刪除文件',
      create_select_title: '將添加到此條目的文件',
      create_title: '創建新條目',
      dashboard_actions: '行動',
      dashboard_comment: '批准條目',
      dashboard_create: '創建條目',
      dashboard_search: '搜索條目',
      dashboard_title: '儀表板',
      detail_active: '條目已激活',
      detail_anotation: '註釋',
      detail_author: '作者 (用戶/公司)',
      detail_comment_process: '批准過程',
      detail_disabled: '禁用的條目',
      detail_eid10: '描述',
      detail_eid1: '可選記錄標識符 (ID1)',
      detail_eid20: '描述',
      detail_eid2: '可選記錄標識符 (ID2)',
      detail_files: '條目文件',
      detail_node: '創建記錄的網絡節點',
      detail_timestamp: '已創建',
      entry_id: '條目ID',
      entry_private: '私人條目',
      entry_public: '公開條目',
      example: '例如',
      expires: '到期',
      hash_info: '插入的散列碼應使用SHA-256算法創建。 使用不同的算法, 使用此文件進行記錄搜索可能會出現問題。',
      here: '這裡',
      is_in_progress: '正在处理。。。',
      landingpage_text: 'BlockchainNotarius服務使用區塊鏈技術來安全驗證電子文件。',
      landingpage_text_1: '可以在不同的節點上驗證條目的存在。 可以找到節點列表',
      landingpage_title2: '你可以信任的',
      landingpage_title: '電子文件',
      landingpage_via_files: '包含文件',
      landingpage_via_files_text_1: '以純文本格式輸入文件散列碼',
      landingpage_via_files_text_2: '文件將不會被上載, 文件僅用於計算散列碼',
      landingpage_via_hash: '登录ID',
      landingpage_via_hash_text_1: '上载后分配给条目的输入六位数条目ID, 或输入由该条目除以分号后的文件哈希码',
      langingpage_text_2: '如果要提交條目或使用元數據搜索條目',
      license_use: '選擇',
      license_id: 'ID',
      license_subscription_id: 'ID 訂閱',
      license_usage: '用法',
      license_expiration: '過期',
      login: '登錄',
      login_advantech: 'MyAdvantech 登錄',
      login_advantech_error: '發生錯誤。請稍後再試。',
      login_advantech_error_license: '很遺憾，您的帳戶沒有有效的許可證。',
      login_advantech_user: '正在獲取用戶信息…',
      login_advantech_license: '正在獲取有效的許可證…',
      login_advantech_license_choose: '請選擇您要使用的許可證。',
      login_bad: '錯誤的用戶名或密碼',
      login_elachain: 'ElAchain 登錄',
      login_email: '電子郵件',
      login_forgotten_password: '忘記密碼了？',
      login_hfc: 'Connection to ElAchain network...',
      login_hfc_error: 'Error connection to ElAchain network. Try later or conntact node administrator.',
      login_login: '登錄',
      login_password: '密碼',
      login_redirect: '重定向到MyAdvantech登录',
      mycommentedentries_title: '我的批准條目',
      myentry_title: '我的條目',
      no: '否',
      no_file_selected: '未選擇任何文件',
      no_search_results: '未找到結果',
      node: '節點',
      or: '或',
      query_anotation: '註釋',
      query_author: '文檔作者',
      query_author_p: '比如 jannovak@elachain.ci',
      query_ex_p: '例如合同編號或作者選擇的其他標識符',
      query_exid1: '可選ID1',
      query_exid2: '可選ID2',
      query_from: '創建自',
      query_name: '條目名稱',
      query_name_p: '例如使用的模板',
      query_on_node: '在節點上創建',
      query_on_node_p: 'blockchain.elachain.cz',
      query_org: '文檔作者組織',
      query_org_p: '比如 blockchain.elachain.cz',
      query_state: '條目狀態',
      query_state_a: '已激活',
      query_state_n: '無效',
      query_state_v: '全部',
      query_to: '創建到',
      query_where: '在節點上創建',
      remove: '清除',
      search_advanced: '按元數據',
      search_entry: '搜索條目',
      search_files: '按文件 / ID',
      search_results: '搜索結果',
      search_title: '條目搜索',
      sidebar_comment: '批准狀態',
      sidebar_create: '創建條目',
      sidebar_dashboard: '儀表板',
      sidebar_logout: '退出',
      sidebar_my_commented_entries: '我的已批准的條目',
      sidebar_my_entries: '我的條目',
      sidebar_search: '搜索條目',
      submit_record: '是的, 創建',
      support: '支持',
      table_approval_state: '批准狀態',
      table_created: '已創建',
      table_entry_files: '條目中的文件',
      table_entry_name: '條目名稱',
      table_id: '條目ID',
      table_state: '狀態',
      wait_please: '請稍等',
      yes: '是',
    }),

    new MLanguage('zh-cn').create({
      about: '关于',
      action: '行动',
      approval_allow: '批准的文件',
      approve_at: '批准于',
      approve_by: '批准者',
      approve_comment: '评论',
      approve_node: 'Approved on node',
      approvestatus_approved: '已批准',
      approvestatus_approvedWithComments: '已批准并包含评论',
      approvestatus_notApproved: '未批准',
      approvestatus_waitingForApproval: '等待批准',
      approvestatus_withoutApproval: '未经批准',
      authenticator_code: "Authenticator code",
      authenticator_code_bad: "Wrong code",
      change_record: '编辑条目',
      close: '关闭',
      comment: '评论',
      comment_approved: '已批准',
      comment_approved_with: '已批准并包含评论',
      comment_id: '条目ID',
      comment_notapproved: '未批准',
      comment_ok: '好',
      comment_pin: '密码',
      comment_t: '条目批准',
      comment_title: '搜索条目以供批准',
      compare: '比较',
      confirm: '确认',
      confirmed_hash: '已确认的散列',
      confirmed_results: '已验证的结果',
      create_confirm_subtitle: '创建后, 您将无法更改此记录, 只能添加新记录。',
      create_confirm_title: '是否确实要创建此记录？',
      create_create: '创建新条目',
      create_metadata: '2. 条目元数据',
      create_metadata_anotation_placeholder: '最多500个字符',
      create_metadata_anotation_title: '注释',
      create_metadata_approval_check: '允许批准文件',
      create_metadata_approval_title: '文件正被批准',
      create_metadata_entryname_help: '* 要求的',
      create_metadata_entryname_placeholder: '比如, 与ABC公司签订合同',
      create_metadata_entryname_title: '条目名称',
      create_metadata_id1: '您的自定义标志 (ID1) 以供条目',
      create_metadata_id2: '您的自定义标志 (ID2) 以供条目',
      create_metadata_visibility: '条目可见性',
      create_metadata_visibility_priv: '私人',
      create_metadata_visibility_pub: '公开',
      create_select_download_url: '下载文件的网址',
      create_select_file: '选择文件选择',
      create_select_file_add: '添加文件',
      create_select_file_select: '选择条目文件',
      create_select_file_text_1: '拖放/选择一个或多个文件 (.pdf, .jpg, .png)',
      create_select_file_text_2: '文件用于在计算机上本地计算Sha-256散列。文件不会被上载。 ',
      create_select_files: '1. 文件选择',
      create_select_hashes: '选择文件 散列码',
      create_select_hashes_add: '将此文件添加到条目中',
      create_select_hashes_hash_help: '出于可比性原因, 输入的散列码应由sha-256算法创建。 ',
      create_select_hashes_hash_placeholder: '比如. jidqwuqwhd0f368a8e940524eb9649fac',
      create_select_hashes_hash_title: '文件 散列码',
      create_select_hashes_name_placeholder: '比如. 合同.pdf',
      create_select_hashes_name_title: '文件名',
      create_select_no_file: '未选择任何文件',
      create_select_remove: '刪除文件',
      create_select_title: '将添加到此条目的文件',
      create_title: '创建新条目',
      dashboard_actions: '行动',
      dashboard_comment: '批准条目',
      dashboard_create: '创建条目',
      dashboard_search: '搜索条目',
      dashboard_title: '仪表板',
      detail_active: '条目已激活',
      detail_anotation: '注释',
      detail_author: '作者 (用戶/公司)',
      detail_comment_process: '批准过程',
      detail_disabled: '禁用的条目',
      detail_eid10: '描述',
      detail_eid1: '可选记录标识符 (ID1)',
      detail_eid20: '描述',
      detail_eid2: '可选记录标识符 (ID2)',
      detail_files: '条目文件',
      detail_node: '创建记录的网络节点',
      detail_timestamp: '已创建',
      entry_id: '条目ID',
      entry_private: '私人条目',
      entry_public: '公开条目',
      example: '例如',
      expires: '到期',
      hash_info: '输入的散列码应使用SHA-256算法创建。使用不同的算法, 使用此文件进行记录搜索可能会出现问题。 ',
      here: '这里',
      is_in_progress: '正在處理。。。',
      landingpage_text: 'BlockchainNotarius服务使用区块链技术来安全验证电子文件。 ',
      landingpage_text_1: '可以在不同的节点上验证条目的存在。可以找到节点列表',
      landingpage_title2: '你可以信任的',
      landingpage_title: '电子文件',
      landingpage_via_files: '包含文件',
      landingpage_via_files_text_1: '以纯文本格式输入文件散列码',
      landingpage_via_files_text_2: '文件将不会被上载, 文件仅用于计算散列码',
      landingpage_via_hash: '登录ID',
      landingpage_via_hash_text_1: '上载后分配给条目的输入六位数条目ID, 或输入由该条目除以分号后的文件散列码',
      langingpage_text_2: '如果要提交条目或使用元数据搜索条目',
      license_use: '选择',
      license_id: 'ID',
      license_subscription_id: 'ID 订阅',
      license_usage: '用法',
      license_expiration: '过期',
      login: '登录',
      login_advantech: 'MyAdvantech 登录',
      login_advantech_error: '发生错误。请稍后再试。',
      login_advantech_error_license: '很遗憾，您的帐户没有有效的许可证。',
      login_advantech_user: '正在获取用户信息…',
      login_advantech_license: '正在获取有效的许可证…',
      login_advantech_license_choose: '请选择您要使用的许可证。',
      login_bad: '错误的用户名或密码',
      login_elachain: 'ElAchain 登录',
      login_email: '电子邮件',
      login_forgotten_password: '忘记密码了？',
      login_hfc: 'Connection to ElAchain network...',
      login_hfc_error: 'Error connection to ElAchain network. Try later or conntact node administrator.',
      login_login: '登录',
      login_password: '密码',
      login_redirect: '重定向到MyAdvantech登录',
      mycommentedentries_title: '我的批准条目',
      myentry_title: '我的条目',
      no: '否',
      no_file_selected: '未選擇任何文件',
      no_search_results: '未找到結果',
      node: '節點',
      or: '或',
      query_anotation: '註釋',
      query_author: '文檔作者',
      query_author_p: '比如 jannovak@elachain.ci',
      query_ex_p: '例如合同編號或作者選擇的其他標識符',
      query_exid1: '可选ID1',
      query_exid2: '可选ID2',
      query_from: '创建自',
      query_name: '条目名称',
      query_name_p: '例如使用的模板',
      query_on_node: '在节点上创建',
      query_on_node_p: 'blockchain.elachain.cz',
      query_org: '文档作者组织',
      query_org_p: '比如 blockchain.elachain.cz',
      query_state: '条目状态',
      query_state_a: '已激活',
      query_state_n: '无效',
      query_state_v: '全部',
      query_to: '创建到',
      query_where: '在节点上创建',
      remove: '清除',
      search_advanced: '按元数据',
      search_entry: '搜索条目',
      search_files: '按文件 / ID',
      search_results: '搜索结果',
      search_title: '条目搜索',
      sidebar_comment: '批准状态',
      sidebar_create: '创建条目',
      sidebar_dashboard: '仪表板',
      sidebar_logout: '退出',
      sidebar_my_commented_entries: '我的已批准的条目',
      sidebar_my_entries: '我的条目',
      sidebar_search: '搜索条目',
      submit_record: '是的, 创建',
      support: '支持',
      table_approval_state: '批准状态',
      table_created: '已创建',
      table_entry_files: '条目中的文件',
      table_entry_name: '条目名称',
      table_id: '条目ID',
      table_state: '状态',
      wait_please: '请稍等 ',
      yes: '是',
    }),
  ],
})
