<template>
  <div>
    <div  v-if="items.length>0">
      <b-table striped hover :items="items" :fields="fields()" class="entry-table">

        <span slot="status"><font-awesome-icon icon="circle"/></span>
        <span slot="link" slot-scope="data" v-html="data.value"></span>

        <span slot="timestamp" slot-scope="data" v-html="(new Date(data.value)).toLocaleDateString('cs')"></span>
        <span slot="approveStatus2">
          <font-awesome-icon icon="check" class="text-success"/>
          <font-awesome-icon icon="minus" class="text-secondary"/>
          <font-awesome-icon icon="clock" class="text-warning"/>
          <font-awesome-icon icon="times" class="text-danger"/>
        </span>
        <span slot="id" slot-scope="data" >
          <a class="w-100 d-inline-block bg-semidark p-1 rounded" v-bind:href="'#/entry/'+data.value" v-text="data.value" ></a> <br/>
          <span v-if="g_last_query_hashes.length>0" v-text="$ml.get('confirmed_hash')"></span> <br/>
          <span v-if="g_last_query_hashes.length>0" class="w-100 d-inline-block bg-success p-1 rounded" style="font-size: 0.7em;" v-text="g_last_query_hashes[0]" ></span>
        </span>
      </b-table>
    </div>
    <div v-else>
      <h3 v-text="$ml.get('no_search_results')"></h3>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  props: ['items'],
  computed: mapGetters(['g_last_query_hashes']),
  data () {
    return {
      test: 'aaa',
    }
  },
  methods: {
    fields () {
      return [
        {
          key: 'status',
          label: this.$ml.get('table_state'),
          sortable: true,
          tdClass: value => {
            return (value === 'active' ? 'text-success' : 'text-danger') + ' text-center min'
          },
        },
        {
          key: 'id',
          label: this.$ml.get('table_id'),
          /* sortable: true, */
          tdClass: value => { return 'min' },
        },
        {
          key: 'link',
          label: this.$ml.get('table_entry_name'),
          sortable: true,
        },
        {
          key: 'timestamp',
          label: this.$ml.get('table_created'),
          sortable: true,
          tdClass: value => { return 'min' },
        },
        {
          key: 'nodeId',
          label: this.$ml.get('node'),
          sortable: true,
          tdClass: value => { return 'min' },
        },
        {
          key: 'approveStatus2',
          label: this.$ml.get('table_approval_state'),
          sortable: true,
          thClass: 'min',
          tdClass: value => {
            return 'min text-center approval approval_' + value
          },
        },
      ]
    },
  },
}
</script>
<style>
  .approval .fa-minus,
  .approval .fa-clock,
  .approval .fa-check,
  .approval .fa-times {
    display: none;
  }

  .approval.approval_confirmed .fa-check,
  .approval.approval_pending .fa-clock,
  .approval.approval_deny .fa-times,
  .approval.approval_none .fa-minus {
    display: inline-block;
  }

  .entry-table thead {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
  }

  .form-cust {
    width: auto;
  }

  tr td.min,
  tr th.min {
    width: 1% !important;
    white-space: nowrap;
  }
</style>
